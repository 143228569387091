import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  constructor() { }

  getPages() {
    return [
      {
        title: 'Inicio',
        url: '/tabs/tab1',
        icon: 'home'
      },
      {
        title: 'Artículos',
        url: '/tabs/products',
        icon: 'basket'
      },
      {
        title: 'Categorías',
        url: '/tabs/category',
        icon: 'md-grid'
      },
      {
        title: 'Chat',
        url: '/tabs/tab2',
        icon: 'chatboxes'
      },
      {
        title: 'Favoritos',
        url: '/tabs/tab3',
        icon: 'heart'
      },
      {
        title: 'Histórico',
        url: '/tabs/historico',
        icon: 'time'
      },
      {
        title: 'Mi cuenta',
        url: '/tabs/tab5',
        icon: 'person'
      }/*,
      {
        title: 'Pedidos',
        url: '/tabs/orders',
        icon: 'md-checkmark-circle-outline'
      }*/
    ];
  }
}
