import {Injectable} from '@angular/core';

import {HttpAngularProvider} from '../http-angular/http-angular';
import {HttpNativeProvider} from '../http-native/http-native';
import { Platform } from '@ionic/angular';

@Injectable()
export class HttpProvider {
    private http: HttpNativeProvider | HttpAngularProvider;

    constructor(private platform: Platform, private angularHttp: HttpAngularProvider, private nativeHttp: HttpNativeProvider) {
        //this.http = this.platform.is('ios') || this.platform.is('android') ? this.nativeHttp : this.angularHttp;
        this.http = this.platform.is('desktop') || this.platform.is('mobileweb') ? this.angularHttp : this.nativeHttp;
        console.log(this.platform);
    }

    public get(url: string, params?: any, options?: any) {
        return this.http.get(url, params, options);
    }

    public put(url: string, params?: any, options?: any) {
        return this.http.put(url, params, options);
    }


    public del(url: string, params?: any, options?: any) {
        console.log(url);
        return this.http.del(url, params, options);
    }

    public post(url: string, params?: any, options?: any) {
        return this.http.post(url, params, options);
    }
}
