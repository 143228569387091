import { Injectable } from '@angular/core';
import { Product } from '../models/product.model';
import { Carro } from '../models/carro.model';
import { Platform } from '@ionic/angular';
import { parametros } from '../models/parametros.model';
import { Category } from '../models/category.model';
import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';

@Injectable({
    providedIn: 'root'
})
export class GlobalService
{

    constructor(    private platform: Platform,
                    private nativeStorage: NativeStorage )
    {
        //this.host = 'https://covadul.site/overapi';
        //this.host = 'https://overlaysistemas.com/overapi2';
        //this.host = 'https://universosnack.com/overapi';

        //this.host = 'https://manjaral.com/overapi';
        //this.host = 'http://192.168.0.224:8888/slim4'; // Vioque local tests
        //this.host = 'https://monforte.shop/Admin_sinc2/overapi2';
        //this.host = 'http://192.168.0.236:8000/Admin_sinc2/overapi2';
        //this.host = 'https://distribucionesgoyo.es/Admin_sinc2/overapi2';
        //this.host =  'https://salom.overlink.es/Admin_sinc2/overapi2';
        //this.host = 'https://exclusivaspeco.overlink.es/Admin_sinc2/overapi2';
        //this.host = 'https://casalma.overlink.es/Admin_sinc2/overapi2';
        //this.host = 'https://adame.overlink.es/Admin_sinc2/overapi2';
        //this.host = 'https://overlay.overlink.es/Admin_sinc2/overapi2';
        //this.host =  'https://disantos.overlink.es/Admin_sinc2/overapi2';
        //this.host =  'https://esparcia.overlink.es/Admin_sinc2/overapi2';
        //this.host =  'https://losfuenteños.overlink.es/Admin_sinc2/overapi2';
        this.host = 'https://alcruz.overlink.es/Admin_sinc2/overapi2';
        //this.host =  'https://monforte.overlink.es/Admin_sinc2/overapi2';
        this.esWeb = platform.is('desktop') || platform.is('mobileweb');

        this.nativeStorage.getItem('busquedaExactaPorCodigo')
        .then(
            data => {
                this.busquedaExactaPorCodigo = data;
            },
            error => { }
        );

        
        console.log( 'inicio');
    }

    // Host
    public host: string;

    // filtro categorias
    public filtroCat: string = '';

    public versionAPI = "1.0.11";
    // Titulo
    //public titulo: string = 'Emicela';

    //public titulo: string = 'Casalma S.L.';
    //public telefono: string = '673 73 72 79';
    //public email: string = 'administracion4@casalma.com';

    //public titulo   : string = 'Overlay S.A.';
    //public telefono : string = '968 12 35 55';
    //public email    : string = 'info@overlay.es';

    //public titulo: string = 'Esparcia Café';
    //public telefono : string = '';
    //public email    : string = '';


    //public titulo: string = 'Los Fuenteños';
    //public telefono : string = '620581009';
    //public email    : string = 'logistica.losfuentenos@gmail.com';

    public titulo: string = 'Alcruz Canarias';
    public telefono: string = '928821458';
    public email: string = ' central@alcruzcanarias.com';

    //public titulo: string = 'Monforte Distribuciones 2016, S.L. ';
    //public telefono : string = '976169055';
    //public email    : string = 'buzon@monforte.es';


    //public titulo: string = 'ADAME quality service';
    //public telefono : string = '';
    //public email    : string = '';

    //public titulo: string = 'Exclusivas Peco';
    //public titulo: string = 'Manjaral';
    //public titulo: string = 'Monforte';
    //public titulo: string = 'Covadul';

    //public titulo: string = 'Overlay Software';
    //public telefono: string = '968 500 176';
    //public email: string = 'lcegarra@overlay.es';

    //public titulo: string = 'Distribuciones Alimenticias Salom';
    //public titulo: string = 'Esparcia Café';
    //public titulo: string = 'Distribuciones Goyo';
    //public titulo: string = 'Distribuciones Santos S.L.';

    public url = '';

    // List of product
    public products: Product[];

    // List of product
    public productsPref: Product[];

    // List of categories
    public categorias: Category[];

    // Lista de parametros
    public parametros: parametros;

    // Parametro 074
    public decimalesUnidades074: number;

    // Parametro 329
    public unidadMedidaMiniaturas329: number;

    // Parametro L03
    public solicitoFechaRepartoL03: boolean;

    // Parametro L04
    public ocultoUnidadNoDefectoL04: boolean;

    // Parametro L05
    public textoImpuestosL05: string;

    // Parametro L06
    public carritoSumaUnidadesL06: boolean = false;

    // Parametro L07
    public usoAnotacionesDocumentosL07: boolean = false;

    // Parametro L06
    public codigoIncidenciaAnotacionesL08: string;

    // Parametro L06
    public multiplesCarritosL09: boolean = false;

    // Parametro 519
    public diasAvanceFechaReparto519: string;

    //Fecha Reparto ( L03 y 519 )
    public fechaReparto: Date = null;

    // Filtro
    public opciones: any = null;

    // productos del carro
    public cartProducts: Carro;

    // categoria de productos activa
    public catProducts: string;

    // nombre categoria de productos activa
    public catName: string;

    // Lista de productos del historico
    public historico: Product[];

    // Lista de productos deseados
    public whislist: Product[];

    //control de cambio
    public cambio = false;

    //control de carga
    public cargando = false;

    public searchTerm: string = "";

    public isOptionSearchOpen: boolean = false;

    public textoComoBusco: string = "Buscar productos";

    //control de cambio
    public token = '';

    //identificador de máquina
    public deviceID = '';

    public esWeb: boolean;

    //articulos en el carro
    public artcart = 0;

    //articulos en el carro
    public mensajesSinLeer = 0;

    public busquedaExactaPorCodigo = false;

    //Articulo al chat
    public itemChat;

    public usuarioId = '';

    public grupo = '';

    public usuario = '';

    public password = '';

    public muestroPrecios : boolean = true;

    rellenarCeros(cadena: string, longitud: number): string
    {
        if (cadena.length >= longitud)
        {
            return cadena;
        } else
        {
            const cerosFaltantes = longitud - cadena.length;
            const ceros = '0'.repeat(cerosFaltantes);
            return ceros + cadena;
        }
    }

    showHidenOpciones() {
        this.isOptionSearchOpen = !this.isOptionSearchOpen;
    }

    busquedaPorCodigo()
    {
        
        this.searchTerm = '';
        this.textoComoBusco = "Buscar por código";    
        this.isOptionSearchOpen = false; // Oculta el menú después de hacer clic en la opción
        this.busquedaExactaPorCodigo = true;
        this.nativeStorage.setItem('busquedaExactaPorCodigo', this.busquedaExactaPorCodigo);
    }

    busquedaPorContenida()
    {
        // Lógica para la opción 2
        
        this.searchTerm = '';
        this.textoComoBusco = "Buscar productos";
        this.isOptionSearchOpen = false; // Oculta el menú después de hacer clic en la opción
        this.busquedaExactaPorCodigo = false;
        this.nativeStorage.setItem('busquedaExactaPorCodigo', this.busquedaExactaPorCodigo);
    }

}
