import { Component, OnInit, Input } from '@angular/core';
import { PopoverController, ModalController} from '@ionic/angular';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { ProductDetailsComponent } from '../../pages/product-details/product-details.component';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnInit {
  @Input() producto: any;
  constructor(private route: ActivatedRoute, public modalController: ModalController, 
    private router: Router, private popoverController: PopoverController, public global: GlobalService) { }

  ngOnInit() {
    console.log(this.producto);
  }

  chat() {
    // code for setting wifi option in apps
    console.log('chat');
    console.log(this.producto);
    this.global.itemChat = this.producto;
    this.router.navigate(['/tabs/tab2']);
    this.popoverController.dismiss();
  }

  detalles() {
    // code for setting wifi option in apps
    console.log('detalles');
    this.goToProductDetails(this.producto.codigo);
    this.popoverController.dismiss();
  }

  async goToProductDetails(item1) {

    const modal = await this.modalController.create({
      component: ProductDetailsComponent,
      componentProps: { producto: item1 }
    });
    return await modal.present();
  }


}
