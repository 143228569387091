import { Component } from '@angular/core';
import { Platform, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { MenuController } from '@ionic/angular';
import { Router } from '@angular/router';
import { PagesService } from './services/pages.service';
import { GlobalService } from './services/global.service';
import { Network } from '@awesome-cordova-plugins/network/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public appPages = [];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private menuController: MenuController,
    private router: Router,
    private pagesService: PagesService,
    public global: GlobalService,
    public alertController: AlertController,
    public network: Network
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.platform.backButton.subscribeWithPriority(10, () => {
        console.log('Boton retroceder pulsado');
        this.showExitConfirm();
     });
      // Get Menus For Side Menu
      this.appPages = this.pagesService.getPages();
    });

    // watch network for a disconnection
    this.network.onDisconnect().subscribe(() => {
      console.log('Aqui red desconectada :-(');
    });

    this.network.onConnect().subscribe(() => {
      setTimeout(() => {
        console.log('Aqui red conectada :-)');
      }, 2000 );
      
    });
  }

  showExitConfirm() {
    this.alertController.create({
      header: 'Salir de la aplicación',
      message: '¿Desea salir de la aplicación?',
      backdropDismiss: false,
      buttons: [{
        text: 'No salir',
        role: 'cancel',
        handler: () => {
          console.log('Application exit prevented!');
        }
      }, {
        text: 'Salir',
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    })
      .then(alert => {
        alert.present();
      });
  }

  // Signout Button
  signout() {
    this.router.navigateByUrl('/signin');
    this.menuController.close(); // cerramos el menú lateral
  }
}
