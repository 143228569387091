import { Directive, ElementRef, HostListener  } from '@angular/core';

@Directive({
  selector: '[appTitleCase]'
})
export class TitleCaseDirective {

  constructor(private el: ElementRef) {
  }

  @HostListener('keyup', ['$event'])
  inputChanged(event) {
    console.log('Directiva');
    if (event.target.value) {
      const arr: string[] = event.target.value.split('');
      arr[0] = arr[0].toUpperCase();
      event.target.value = arr.join('');
   }
  }
}
