import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';

import { from as observableFrom } from 'rxjs';
//import 'rxjs/add/observable/fromPromise';
import { GlobalService } from '../../services/global.service';

@Injectable()
export class HttpNativeProvider {
    constructor(private http: HTTP, private global: GlobalService) {}

    public get(url, params?: any, options: any = {}) {
        console.log('Llega hasta el get:' + url + params);
        //let responseData = this.http.get(url, params, {})
          //  .then(resp => options.responseType == 'text' ? resp.data : JSON.parse(resp.data));
        const responseData = this.http.sendRequest(url, {method: 'get', data: {}, headers: {Authorization: 'Bearer ' + this.global.token}}).then(resp => options.responseType === 'text' ? resp.data : JSON.parse(resp.data)).catch(response => {
            console.log(response.status);
            console.log(response.error);
        });
        return observableFrom(responseData);
    }

    public put(url, params?: any, options: any = {}) {
        /*let responseData = this.http.put(url, params, {})
            .then(resp => options.responseType == 'text' ? resp.data : JSON.parse(resp.data));*/
        const responseData = this.http.sendRequest(url, {method: 'put', data: {}, headers: {Authorization: 'Bearer ' + this.global.token}}).then(resp => options.responseType === 'text' ? resp.data : JSON.parse(resp.data)).catch(response => {
                console.log(response.status);
                console.log(response.error);
            });

        return observableFrom(responseData);
    }

    
    public del(url, params?: any, options: any = {}) {
        /*let responseData = this.http.delete(url, params, {})
            .then(resp => options.responseType == 'text' ? resp.data : JSON.parse(resp.data));*/
        const responseData = this.http.sendRequest(url, {method: 'delete', data: {}, headers: {Authorization: 'Bearer ' + this.global.token}}).then(resp => options.responseType === 'text' ? resp.data : JSON.parse(resp.data)).catch(response => {
                console.log(response.status);
                console.log(response.error);
            });

        return observableFrom(responseData);
    }

    public post(url, params?: any, options: any = {}) {
        let responseData = this.http.post(url, params, {})
            .then(resp => options.responseType == 'text' ? resp.data : JSON.parse(resp.data));

        return observableFrom(responseData);
    }
}