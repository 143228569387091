import { Component, OnInit, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { ApiService } from '../../../api.service';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss'],
})
export class VerificationComponent implements OnInit {
  @Input() email: string;
  resultado = '';
  check = '0';
  constructor(public modalController: ModalController, public apiService: ApiService) { }

  ngOnInit() {}

  ionViewWillEnter(){
   console.log(this.email);
   this.recupera(this.email);
  }
  async recupera(email) {
    // this.dismiss();
    this.resultado = await this.apiService.recuperaPass(email);
    console.log(this.resultado);
    if (this.resultado === 'Recuperada') {
        this.check = '1';
      } else {
        this.check = '2';
      }
  }
  // Back to previous page function
  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
