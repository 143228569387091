import { Component, OnInit } from '@angular/core';
import { PopoverController, ModalController} from '@ionic/angular';
import { filtros } from 'src/app/models/filtros.model';
import { ApiService } from '../../api.service';
import { ProductsService } from '../../services/products.service';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {

  priceRange: any;
  filtros: filtros;
  opciones: any = [];
  familia: any;
  carac:any = [];
  familias = '';
  lower = 0;
  upper = 300;
  precios:any = [];

  constructor(public ApiService: ApiService, private popoverController: PopoverController,
              public modalController: ModalController, private productsService: ProductsService,
              public global: GlobalService) { }

  ngOnInit() {
    //this.configure();
    this.damefiltros();
  }

  ionViewDidEnter(){
   if (this.global.opciones !== null) {
    for (let u = 0; u < this.global.opciones.length; u++) {
       console.log(this.global.opciones[u].codigoInt);
       console.log(this.global.opciones[u].opcion);
       switch (this.global.opciones[u].codigoInt) {
        case 'Familia': {
           this.familias = this.global.opciones[u].opcion;
           console.log('Esta es la familia:'+this.familias)
           break;
        }
        case 'Precio': {
          this.precios = this.global.opciones[u].opcion;
          this.lower = this.global.opciones[u].opcion.lower;
          this.upper = this.global.opciones[u].opcion.upper;
          break;
       }
       case '01': {
        this.carac[0] = this.global.opciones[u].opcion;
        break;
     }
     case '02': {
      this.carac[1] = this.global.opciones[u].opcion;
      break;
      }
      case '03': {
        this.carac[2] = this.global.opciones[u].opcion;
        break;
      }
      case '04': {
        this.carac[3] = this.global.opciones[u].opcion;
        break;
      }
      case '05': {
        this.carac[4] = this.global.opciones[u].opcion;
        break;
      }
      case '06': {
        this.carac[5] = this.global.opciones[u].opcion;
        break;
      }
      case '07': {
        this.carac[6] = this.global.opciones[u].opcion;
        break;
      }
      case '08': {
        this.carac[7] = this.global.opciones[u].opcion;
        break;
      }
      case '09': {
        this.carac[8] = this.global.opciones[u].opcion;
        break;
      }
      case '10': {
        this.carac[9] = this.global.opciones[u].opcion;
        break;
      }
        default: {
           // statements;
           break;
        }
     }
    }
    this.opciones = this.global.opciones;
  }
  this.configure();
  }

  damefiltros() {
    this.productsService.dameFiltros().then(filtros => {
      this.filtros = filtros;
    });
  }

  addopcion(codigoInt, valor) {
    console.log(codigoInt);
    console.log(valor.target.value);
    let i = 0;
    for (const numero of this.opciones) {
      console.log(numero.codigoInt);
      if (numero.codigoInt === codigoInt) {
        console.log(numero);
        console.log(i);
        this.opciones.splice(i, 1);
      }
      i = ++i;
    }
    const opcion = valor.target.value;
    this.opciones.push({codigoInt, opcion});
    console.log('Final' + JSON.stringify(this.opciones));
  }

  precio(event) {
    const valor = event;
    console.log(valor);
    this.addopcion('Precio', valor);
  }
reset(){
  this.priceRange = {
    lower: 0,
    upper: 300
  };
}
  configure() {
    this.priceRange  = {
      lower: this.lower,
      upper: this.upper
    };
    console.log('Configure');
  }
// Back to previous page function
dismiss(opcion) {
  console.log(opcion);
  if (opcion === 1) {
    this.global.opciones = this.opciones;
    this.modalController.dismiss(this.opciones);
  } else {
    this.global.opciones = null;
    this.modalController.dismiss(null);
  }

}

}
