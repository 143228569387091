import { Carro } from './../models/carro.model';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { isNull } from 'util';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  products: Carro;

  constructor(private apiService: ApiService) {

   }

    async cart(): Promise<Carro> {
        this.products = await this.apiService.getCarrito('/api/cart');
        console.log('Productos en carro: ' + this.products);
        return this.products;
    }

    /*async addcart(codigo): Promise<Product[]> {
        this.products = await this.apiService.getArticulos('/api/articulo/' + codigo);
        console.log('Producto: ' + this.products);
        return this.products;
    }

    async delcart(codigo): Promise<Product[]> {
        this.products = await this.apiService.getArticulos('/api/articulo/' + codigo);
        console.log('Producto: ' + this.products);
        return this.products;
    }*/
}
